import { SetStateAction, useState } from "react";
import * as styles from "./Signature.module.scss";
import logo from "../assets/images/logo.png";
import SEO from "../components/Helmet/SEO";

function EmailSignatureGenerator() {
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);

  const handleNameChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setName(event.target.value);
  };

  const handlePositionChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setPosition(event.target.value);
  };

  const handleEmailChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setPhone(event.target.value);
  };

  const handlePreviewClick = () => {
    if (name && position && email && phone) {
      setPreviewVisible(true);
    } else {
      alert("Please fill in all fields.");
    }
  };

  const copyHTML = () => {
    const signatureHTML = document.getElementById("signatureHtml")?.outerHTML;
    navigator.clipboard
      .writeText(
        signatureHTML ||
          "The html generated with an error. Please contact the developer."
      )
      .then(() => {
        alert("HTML copied to clipboard!");
      });
  };

  return (
    <>
      <SEO
        metaTitle={"Signature"}
        metaDescription={"Signature Generator"}
        keywords={""}
        preventIndexing={true}
        rating={false}
        faqs={[]}
      />
      <div className={styles.emailSignatureGenerator}>
        <h1>Email Signature Generator</h1>
        <div className={styles.formContainer}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={handleNameChange}
          />
          <input
            type="text"
            placeholder="Position"
            value={position}
            onChange={handlePositionChange}
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          <input
            type="tel"
            placeholder="Phone"
            value={phone}
            onChange={handlePhoneChange}
          />
          <button
            onClick={handlePreviewClick}
            disabled={!name || !position || !email || !phone}
          >
            Preview
          </button>
          <button
            className={styles.copyButton}
            onClick={copyHTML}
            disabled={!name || !position || !email || !phone}
          >
            Copy HTML
          </button>
        </div>
        {previewVisible && (
          <div className={styles.previewContainer}>
            <table
              id="signatureHtml"
              style={{
                fontFamily: "'Inter', Helvetica, Arial, sans-serif",
                fontSize: "14px",
                fontWeight: "lighter",
                lineHeight: "27px",
                maxWidth: "406px",
                color: "#212121",
                margin: "0",
                padding: "21px 30px",
                border: "none",
                borderSpacing: "0",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ textAlign: "left", paddingBottom: "3px" }}>
                    <img
                      src="https://via.work/images/via-logo-email.png"
                      alt="Via Work logo"
                      style={{ height: "19px", width: "56px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ border: "none", borderTop: "1px solid #E5E5E5" }}
                  ></td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "5px" }}>
                    <table style={{ border: "none", borderSpacing: "0" }}>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                              textAlign: "left",
                            }}
                          >
                            {name}
                          </td>
                          <td
                            style={{ padding: "0 15px", textAlign: "center" }}
                          >
                            &bull;
                          </td>
                          <td style={{ textAlign: "left" }}>{position}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table style={{ border: "none", borderSpacing: "0" }}>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          <p style={{ margin: "0" }}>
                            Hire international teams seamlessly
                          </p>
                          <span style={{ margin: "0" }}>
                            <a
                              href="https://via.work"
                              style={{
                                color: "#2877F4",
                                textDecoration: "none",
                              }}
                            >
                              via.work
                            </a>
                            &nbsp;|&nbsp;{email}&nbsp;|&nbsp;{phone}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default EmailSignatureGenerator;
